<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Sebaran <b>Beban Mengajar Dosen</b></div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-start mt-n3">
              <div class="col-3">
                <b-button
                  variant="success"
                  class="ml-1 mt-1"
                  @click="btnPrintOnClick"
                  v-b-tooltip.hover
                  title="Print"
                  size="sm"
                >
                  <i class="fas fa-print px-0"></i>
                </b-button>
                <vue-excel-xlsx
                  :data="items"
                  :columns="columns"
                  :filename="`Sebaran`"
                  :sheetname="`Sebaran`"
                >
                  <b-button
                    class="ml-1 mt-1 btn-green"
                    variant="btn-green"
                    v-b-tooltip.hover
                    title="Cetak Excel"
                    size="sm"
                  >
                    <i class="fas fa-file-excel text-white px-0"></i>
                  </b-button>
                </vue-excel-xlsx>
                <b-button
                  variant="danger"
                  class="ml-1 mt-1"
                  @click="downloadPDF"
                  v-b-tooltip.hover
                  title="Cetak PDF"
                  size="sm"
                >
                  <i class="fas fa-file-pdf text-white px-0"></i>
                </b-button>
              </div>
              <div class="col-md-3 offset-md-2">
                <treeselect
                  v-model="filter.semester_id"
                  :multiple="false"
                  placeholder="Saring Semester"
                  :options="semesters"
                  @input="filterBySemester"
                />
              </div>
              <div class="col-md-4 px-0">
                <b-input-group>
                  <template #append>
                    <b-button
                      variant="success"
                      squared
                      size="sm"
                      @click="search"
                    >
                      Cari
                    </b-button>
                    <b-button variant="danger" squared size="sm" @click="reset">
                      Reset
                    </b-button>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Dosen"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="500"
      filename="Sebaran"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <DistributionPrintPdf :itemProps="items" />
      </section>
    </vue-html2pdf>

    <DistributionPrint :itemProps="items" class="d-none" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import DistributionPrint from "@/view/pages/schedules/DistributionPrint.vue";
import DistributionPrintPdf from "@/view/pages/schedules/DistributionPrintPdf.vue";

export default {
  components: {
    VueHtml2pdf,
    DistributionPrint,
    DistributionPrintPdf,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        semester_id: JSON.parse(window.localStorage.getItem("semester_active"))
          .id,
      },
      // Pagination
      perPage: 40,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Dosen",
          sortable: true,
        },
        {
          key: "pendidikan_fisika",
          label: "Pendidikan Fisika",
          sortable: true,
        },
        {
          key: "fisika",
          label: "Fisika",
          sortable: true,
        },
        {
          key: "layanan",
          label: "Layanan",
          sortable: true,
        },
        {
          key: "mku",
          label: "MKU",
          sortable: true,
        },
        {
          key: "mkdk",
          label: "MKDK",
          sortable: true,
        },
        {
          key: "s2_pendidikan_fisika",
          label: "S2 Pendidikan Fisika",
          sortable: true,
        },
        {
          key: "pasca_unj",
          label: "Pasca UNJ",
          sortable: true,
        },
      ],
      columns: [
        {
          field: "number",
          label: "No",
        },
        {
          field: "name",
          label: "Dosen",
        },
        {
          field: "pendidikan_fisika",
          label: "Pendidikan Fisika",
        },
        {
          field: "fisika",
          label: "Fisika",
        },
        {
          field: "layanan",
          label: "Layanan",
        },
        {
          field: "mku",
          label: "MKU",
        },
        {
          field: "mkdk",
          label: "MKDK",
        },
        {
          field: "s2_pendidikan_fisika",
          label: "S2 Pendidikan Fisika",
        },
        {
          field: "pasca_unj",
          label: "Pasca UNJ",
        },
      ],
      items: [],
      semesters: [],
      // access
      dataLoaded: false,
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },
    async pagination() {
      let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}`;
      let response = await module.paginate(
        "api/schedules/distributions",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },
    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Saring Semester",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getField() {
      this.fields = [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "pendidikan_fisika",
          label: "Pendidikan Fisika",
          sortable: true,
        },
        {
          key: "fisika",
          label: "Fisika",
          sortable: true,
        },
        {
          key: "layanan",
          label: "Layanan",
          sortable: true,
        },
        {
          key: "mku",
          label: "MKU",
          sortable: true,
        },
        {
          key: "mkdk",
          label: "MKDK",
          sortable: true,
        },
        {
          key: "s2",
          label: "pasca",
          sortable: true,
        },
      ];
      let field = {};
      //   let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}`;
      let response = await module.paginate("api/study-programs");

      let items = response.data;
      for (let a = 0; a < items.length; a++) {
        this.fields.push({
          key: items[a].name,
          label: items[a].name,
          sortable: true,
        });
      }

      console.log("fields", this.fields);
      this.dataLoaded = true;
    },
    async btnPrintOnClick() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: "Sebaran",
      };
      await this.$htmlToPaper("printMe", options);
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.semester_id = JSON.parse(window.localStorage.getItem("semester_active")).id;

      this.pagination();
    },
    filterBySemester(evt) {
      if (typeof evt == "undefined") {
        this.filter.semester_id = "";
      }
      this.pagination();
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/log-books/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1109") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sebaran Beban Mengajar Dosen", route: "/log-books" },
    ]);
    this.pagination();
    // this.getField();
    this.checkAccess();
    this.getSemesterOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
