<template>
  <div>
    <div class="row" >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- <b-table
                :items="itemProps"
                :fields="fields"
                class="mt-3"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>
              </b-table> -->
              <table class="table table-bordered">
                <tr>
                  <th>Dosen</th>
                  <th>Pendidikan Fisika</th>
                  <th>Fisika</th>
                  <th>Layanan</th>
                  <th>MKU</th>
                  <th>MKDK</th>
                  <th>S2 Pendidikan Fisika</th>
                  <th>Pasca UNJ</th>
                </tr>
                <template v-for="(item, i) in itemProps">
                  <tr :key="i">
                    <td>{{ item.name }}</td>
                    <td>{{ item.pendidikan_fisika }}</td>
                    <td>{{ item.fisika }}</td>
                    <td>{{ item.layanan }}</td>
                    <td>{{ item.mku }}</td>
                    <td>{{ item.mkdk }}</td>
                    <td>{{ item.s2_pendidikan_fisika }}</td>
                    <td>{{ item.pasca_unj }}</td>
                  </tr>
                  <template v-if="(i + 1) % 17 == 0">
                    <div class="html2pdf__page-break" :key="i + 'a'" />
                    <tr :key="i + 'table-header'">
                      <th>Dosen</th>
                      <th>Pendidikan Fisika</th>
                      <th>Fisika</th>
                      <th>Layanan</th>
                      <th>MKU</th>
                      <th>MKDK</th>
                      <th>S2 Pendidikan Fisika</th>
                      <th>Pasca UNJ</th>
                    </tr>
                  </template>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    itemProps: Array,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        semester_id: "",
      },
      // Pagination
      perPage: 40,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "name",
          label: "Dosen",
        },
        {
          key: "pendidikan_fisika",
          label: "Pendidikan Fisika",
        },
        {
          key: "fisika",
          label: "Fisika",
        },
        {
          key: "layanan",
          label: "Layanan",
        },
        {
          key: "mku",
          label: "MKU",
        },
        {
          key: "mkdk",
          label: "MKDK",
        },
        {
          key: "s2_pendidikan_fisika",
          label: "S2 Pendidikan Fisika",
        },
        {
          key: "pasca_unj",
          label: "Pasca UNJ",
        },
      ],
      columns: [
        {
          field: "number",
          label: "No",
        },
        {
          field: "name",
          label: "Dosen",
        },
        {
          field: "pendidikan_fisika",
          label: "Pendidikan Fisika",
        },
        {
          field: "fisika",
          label: "Fisika",
        },
        {
          field: "layanan",
          label: "Layanan",
        },
        {
          field: "mku",
          label: "MKU",
        },
        {
          field: "mkdk",
          label: "MKDK",
        },
        {
          field: "s2_pendidikan_fisika",
          label: "S2 Pendidikan Fisika",
        },
        {
          field: "pasca_unj",
          label: "Pasca UNJ",
        },
      ],
      items: [],
      // access
      dataLoaded: false,
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}`;
      let response = await module.paginate(
        "api/schedules/distributions",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getField() {
      this.fields = [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "pendidikan_fisika",
          label: "Pendidikan Fisika",
          sortable: true,
        },
        {
          key: "fisika",
          label: "Fisika",
          sortable: true,
        },
        {
          key: "layanan",
          label: "Layanan",
          sortable: true,
        },
        {
          key: "mku",
          label: "MKU",
          sortable: true,
        },
        {
          key: "mkdk",
          label: "MKDK",
          sortable: true,
        },
        {
          key: "s2",
          label: "pasca",
          sortable: true,
        },
      ];
      let field = {};
      //   let filterParams = `&name=${this.filter.name}&semester_id=${this.filter.semester_id}`;
      let response = await module.paginate("api/study-programs");

      let items = response.data;
      for (let a = 0; a < items.length; a++) {
        this.fields.push({
          key: items[a].name,
          label: items[a].name,
          sortable: true,
        });
      }

      console.log("fields", this.fields);
      this.dataLoaded = true;
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.semester_id = "";

      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/log-books/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1109") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sebaran Beban Mengajar Dosen", route: "/log-books" },
    ]);
    this.pagination();
    // this.getField();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
